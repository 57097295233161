import Cookies from 'js-cookie';
import Buy from 'shopify-buy';

const conf: any = {
  domain: 'noise-engineering.myshopify.com',
  storefrontAccessToken: '5d023b7b2d316495d543e30d6b6d8a33',
};

export function toData(obj: any) {
  return JSON.parse(JSON.stringify(obj));
}

//https://www.npmjs.com/package/react-cookie
//https://www.npmjs.com/package/react-cookie-consent
//https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52/

export default class Shop {
  buy: ShopifyBuy;
  cart: Buy.Checkout | null;
  callback: () => void;
  sequence: number;

  constructor() {
    this.buy = Buy.buildClient(conf);
    this.cart = null;
    this.callback = () => undefined;
    this.sequence = 0;
  }

  copy() {
    const s = new Shop();
    s.buy = this.buy;
    s.cart = this.cart;
    s.callback = this.callback;
    s.sequence = this.sequence;
    return s;
  }

  setCallback(cb: () => void) {
    this.callback = cb;
  }

  notify() {
    this.sequence += 1;
    this.callback();
  }

  ensureLoad() {
    if (!this.cart) {
      this.loadOrCreateCart(); //async
    }
  }

  async loadOrCreateCart() {
    const id = this.loadCookieCart();
    this.cart = id ? await this.loadCart(id) : null;
    this.cart = this.cart ? this.cart : await this.createCart();
    return this.cart;
  }

  loadCookieCart(): string | undefined {
    return Cookies.get('ShopCart');
  }

  saveCookieCart(id: string) {
    Cookies.set('ShopCart', id);
  }

  async loadCart(id: string) {
    if (id.length < 10) {
      return null;
    }

    try {
      const cart = await this.buy.checkout.fetch(id);
      this.notify();
      return cart;
    } catch (e) {
      console.log('load cart failure: ', e);
      return null;
    }
  }

  async createCart() {
    this.cart = await this.buy.checkout.create();
    this.saveCookieCart(this.cart.id);
    console.log('created cart: ', this.cart.id);
    this.notify();
    return this.cart;
  }

  async resetCart() {
    this.cart = await this.createCart();
    this.notify();
    return this.cart;
  }

  async addItemToCart(itemId: string) {
    if (!this.cart) {
      this.cart = await this.loadOrCreateCart();
    }

    const lineItemsToAdd = [
      {
        variantId: itemId,
        quantity: 1,
      },
    ];

    console.log('add to cart!');

    this.cart = await this.buy.checkout.addLineItems(
      this.cart.id,
      lineItemsToAdd
    );

    this.notify();

    console.log('added item to cart: ', this.cart.id);
    //console.log("web url ", this.cart.webUrl);
  }

  getCheckoutUrl() {
    return this.cart?.webUrl;
  }

  getLineItemCount() {
    let count = 0;
    if (this.cart && this.cart.lineItems) {
      for (const x of this.cart.lineItems) {
        count += x.quantity;
      }
    }
    return count;
  }

  getLineItems() {
    return this.cart?.lineItems;
  }
  async removeItem(id: string) {
    if (this.cart) {
      await this.buy.checkout.removeLineItems(this.cart?.id, [id]);
      await this.loadOrCreateCart();
      this.notify();
    }
  }
  async setNote(note: string) {
    console.log(this.cart?.id);
    if (this.cart?.id) {
      await this.buy.checkout.updateAttributes(this.cart.id, { note: note });
    }
  }

  async updateLineItem(id: string, count: number) {
    console.log(id, count);
    if (this.cart?.id) {
      await this.buy.checkout.updateLineItems(this.cart.id, [
        {
          id: id,
          quantity: count,
        },
      ]);
      await this.loadOrCreateCart();
      this.notify();
    }
  }

  getCost() {
    return this.cart?.lineItemsSubtotalPrice.amount;
  }
}

//const shop = new Shop();

//export default shop;
